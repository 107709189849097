@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular.woff') format('woff'),
    url('./fonts/Inter-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Medium.woff') format('woff'),
    url('./fonts/Inter-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-SemiBold.woff') format('woff'),
    url('./fonts/Inter-SemiBold.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Bold.woff') format('woff'),
    url('./fonts/Inter-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'InputMono';
  src: url('./fonts/InputMono-Regular.woff') format('woff'),
    url('./fonts/InputMono-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'RoslindaleVariableDisplay';
  src: url('./fonts/RoslindaleVariableDisplay-VF.woff2') format('woff2'),
    url('./fonts/RoslindaleVariableDisplay-VF.woff') format('woff');
  font-style: normal;
  font-weight: 550;
}

html {
  font-family: 'Inter', -apple-system, sans-serif;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background: #111;
  content: #fff;
}

button {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

input {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: 'InputMono', monospace;
}

.decorationsOverviewRuler {
  visibility: hidden !important;
}

.monaco-editor .lines-content .cigr {
  opacity: 0 !important;
}

.cigra {
  box-shadow: 1px 0 0 0 #282828 inset !important;
}

.monaco-editor-hover {
  border-radius: 4px;
  outline: none;
}

.hover-contents.code-hover-contents {
  padding: 10px 14px !important;
}

.monaco-editor-hover ::selection {
  background: #999;
}

.shadow.top {
  display: none !important;
}

.line-numbers {
  user-select: none;
}

.editor-widget {
  outline: none;
}

.tree {
  border-radius: 4px;
  overflow: hidden;
}

.slider {
  border-radius: 8px;
  width: 6px !important;
  margin-top: 4px;
}

.slider.active {
  background: rgba(255, 255, 255, 0.4) !important;
}

.monaco-editor .squiggly-error {
  border-bottom: 2px solid #ff3366;
  background: none !important;
}

.squiggly-warning {
  border-bottom: 2px solid #ff3366;
  background: none !important;
}

.monaco-editor .monaco-editor-hover code {
  color: #bbbbbb !important;
  background-color: #222222 !important;
}
